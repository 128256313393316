<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="content">
      <div></div>
      <div class="content-wrapper">
        <!-- max-width: 768px -->
        
        <img style="width: 150px; height: auto;" v-if="mediaQuery.matches" class="banner-logo" src="../assets/images/mobile-banner-logo.png" alt="numisart-logo">
        <img v-else class="banner-logo" src="../assets/images/banner-logo.png" alt="numisart-logo">
        
        <div class="sub-title">{{ $t("NumistArt, numismatic global centre.") }}</div>
        <div class="title">
          {{ $t("World First Largest Digital Numismatic Gallery") }}
        </div>
        <div class="sub-title">
          {{ $t("Register for FREE membership") }} <br/>
          {{ $t("Register for FREE membership2") }}
        </div>
        
        <!-- <img v-if="mediaQuery.matches" :src="$i18n.locale == 'en' ? enMobile : zhMobile" alt="">
        <img v-else :src="$i18n.locale == 'en' ? enWeb : zhWeb" alt=""> -->
        <router-link to="/register" class="register-btn">{{ $t("Register Now") }}</router-link>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      mediaQuery: '',
      enWeb: require('../assets/images/eng-web.png'),
      enMobile: require('../assets/images/eng-mobile.png'),
      zhWeb: require('../assets/images/zh-web.png'),
      zhMobile: require('../assets/images/zh-mobile.png'),
    }
  },
  mounted () {
    this.mediaQuery = window.matchMedia('(max-width: 768px)')
    console.log("mediaQuery: ", this.mediaQuery);
  }
}
</script>

<style>
.home {
  /* background-image: url('../assets/images/mobile-banner-bg.png'); */
  /* background-position: top left, right bottom; */
  background-repeat: round;
  background-size: 100%;
  height: 100%;
}
.home .content {
  /* padding: 20px; */
  text-align: center;
  margin: 0;
}
.home .banner-logo {
  min-width: 175px;
  max-width: 560px;
  width: 80%;
  margin: 10px;
}
.home .sub-title {
  font-size: 30px;
  padding: 30px 0;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-size: 45px;
  line-height: 68px;
  /* identical to box height */
  text-align: center;
  color: #000000;
  text-shadow: 0px 0px 10px #FFFFFF, 0px 0px 10px #FFFFFF;
}
.home .title {
  color: #FFA135;
  font-weight: bold;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-size: 65px;
  line-height: 98px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 0px 0px 30px #FF6B00, 0px 0px 30px #FF6B00;
}

.home .register-btn {
    text-decoration: none;
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 30px;
    gap: 10px;

    /* width: 222px; */
    height: 56px;

    background: #FFFFFF;
    box-shadow: 0px 5px 10px #FF6B00;
    border-radius: 6px;

    margin: 20px auto;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    text-align: center;
    justify-content: center;

    color: #FFA135;

}

.home .content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
  margin: 0;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .home .title {
    color: #FFA135;
    font-weight: bold;
    font-size: 25px;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: 0px 0px 20px #FF6B00, 0px 0px 10px #FF6B00;
  }
  .home .sub-title {
    font-size: 14px;
    padding: 30px 0;
    font-weight: bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #000000;

    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25), 0px 0px 8px #FFFFFF;
  }
  .home .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    margin: 0;
  }
  .home .content-wrapper {
    /* padding: 20px; */
    /* background: rgba(255, 255, 255, 0.8);
    border: 2px solid #ffffff; */
    border-radius: 12px;
  }
  .home .register-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    width: 168px;
    height: 44px;

    background: #FFFFFF;
    box-shadow: 0px 5px 10px #FF6B00;
    border-radius: 6px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;

    color: #FFA135;
  }
}

@media only screen and (min-width: 1600px) {
  .home {
    /* background: url('../assets/images/banner-bg.png') no-repeat; */
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home .content {
    /* padding: 150px; */
    
  }
  .home .content-wrapper {
    /* padding: 20px; */
    /* background: rgba(255, 255, 255, 0.8);
    border: 2px solid #ffffff; */
    border-radius: 12px;
    
  }
}
</style>
