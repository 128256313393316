import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import Axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)
Axios.defaults.withCredentials = true
Vue.prototype.$http = Axios;

Vue.config.productionTip = false
Vue.use(VueSweetalert2)

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
