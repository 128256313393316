<template>
  <div id="app">
    <div class="language">
      <button @click="$i18n.locale = 'en'">EN</button> |
      <button @click="$i18n.locale = 'zh'">中文</button>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@font-face {
    font-family: Poppins;
    src: url('./fonts/Poppins/Poppins-Regular.ttf');
}
#app {
  background-image: url('./assets/images/mobile-banner-bg.png');
  /* background-position: top left, right bottom; */
  background-repeat: round;
  background-size: 100%;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-attachment: fixed;
}
.language {
  float: right;
  margin: 20px;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: #2c3e50;
  z-index: 9;
}
.language button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}
@media only screen and (min-width: 768px) {
  #app {
    background: url('./assets/images/banner-bg.png') no-repeat;
    background-repeat: round;
    background-size: cover;
    min-height: 100vh;
  }
}
</style>
